import React, { useEffect } from 'react';

import {Select as ChakraSelect, Flex, Input,InputGroup,InputLeftAddon, Spinner, Stack, Checkbox } from '@chakra-ui/react';
import { Formik } from 'formik';
import FileDownload from 'js-file-download';
import Select from 'react-select';
import { useToasts } from 'react-toast-notifications';
import * as yup from 'yup';

import { customStyle } from './styles/SelectStyle';

import { GetReportsDto, ReportsApi, SellersApi, UserNameEntity, UsersApi } from '../../clients';

import { getApiAuthConfig } from '../../services/api.service';

import { DataValues } from './interfaces/DataValues';
import { SellersOptions } from './interfaces/SellersOptions';

import { useAuth } from '../../contexts/AuthProvider';

import Button from '../../components/Button';
import FormControl from '../../components/Form/FormControl';
import FormErrorMessage from '../../components/Form/FormErrorMessage';
import RangeDatePicker, { IPeriod } from '../../components/RangeDatePicker';
import { defaultColors } from '../../config/variables';
import SimpleTable from './Table/Table';
import { ReportStatusEnum } from './enums/ReportStatusEnum';
import { utcToBrazillianEndDate } from '../../services/utc-to-brazillian-end-date.service';

const Reports: React.FC = () => {
	const { addToast } = useToasts();
	const { isBackoffice } = useAuth();
	const apiConfig = getApiAuthConfig();
	const reportsApi = new ReportsApi(apiConfig);
	const sellersApi = new SellersApi(apiConfig);
	const usersApi = new UsersApi(apiConfig);
	const [reportsData, setReportsData] = React.useState<GetReportsDto | undefined>(undefined);
	const [sellersData, setSellersData] = React.useState<SellersOptions[] | undefined>(undefined);
	const [sellerIds, setSellerIds] = React.useState<SellersOptions[] | undefined>([]);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [reportsLoading, setReportsLoading] = React.useState<boolean>(false);
	const [financialReportsLoading, setFinancialReportsLoading] = React.useState<boolean>(false);
	const [transactionNumber, setTransactionNumber] = React.useState<string>('');
	const [licensePlate, setLicensePlate] = React.useState<string>('');
	const [status, setStatus] = React.useState<string>('');
	const [userSearched, setUserSearched] = React.useState<string>('');
	const [onlyInvoiced, setOnlyInvoiced] = React.useState<boolean>(false);
	const [currentPage, setCurrentPage] = React.useState<number>(1);
	const [users, setUsers] = React.useState<UserNameEntity[]>([]);
	const [usersLoading, setUsersLoading] = React.useState<boolean>(false);
	const [limit, setLimit] = React.useState<number>(10);
	const [period, setPeriod] = React.useState<IPeriod[]>([
		{
			startDate: new Date(new Date().setHours(0, 0, 0, 0)),
			endDate: new Date(new Date().setHours(23, 59, 59, 999)),
			key: 'selection',
		},
	]);
	const [dataValues, setDataValues] = React.useState<DataValues>({
		startDate: period[0].startDate.toString(),
		endDate: period[0].endDate.toString(),
		transactionNumber,
		sellerIds: [],
		limit,
		currentPage,
		licensePlate,
		status,
		user_searched: userSearched
	});

	const initialValues = {
		period,
		sellerIds,
		transactionNumber,
		licensePlate,
		status,
		user_searched: userSearched
	};

	const schema = yup.object().shape({
		licensePlate: yup
			.string()
			.matches(/^[a-zA-Z]{3}\d[a-zA-Z\d]\d{2}$/, { message: 'Formato de placa inválido', excludeEmptyString: true }),
	});

	async function fetchSellersData() {
		const sellersResponse = await sellersApi.getSellers();
		const parsedSellers = sellersResponse.map((seller) => ({ value: seller.cns, label: seller.name, id: seller.id }));
		setSellersData(parsedSellers);
	}

	async function fetchData(dataValues: DataValues) {
		setLoading(true);
		const reportsResponse = await reportsApi.getReports({
			startDate: dataValues?.startDate,
			endDate: utcToBrazillianEndDate(new Date(dataValues?.endDate)).toString(),
			limit: dataValues.limit,
			currentPage: dataValues.currentPage,
			transactionNumber: dataValues?.transactionNumber,
			sellerIds: dataValues?.sellerIds?.map((seller) => seller.id).join(','),
			licensePlate: dataValues?.licensePlate,
			status: dataValues?.status,
			userSearched: dataValues.user_searched,
			onlyInvoiced,
		});
		setReportsData(reportsResponse);
		setLoading(false);
	}

	async function exportReports() {
		setReportsLoading(true)
		try{
			const exportReports = await reportsApi.getExportedReport({
				startDate: dataValues?.startDate,
				endDate: utcToBrazillianEndDate(new Date(dataValues?.endDate)).toString(),
				limit: reportsData?.total_items ?? 100,
				currentPage: 1,
				transactionNumber: dataValues?.transactionNumber,
				sellerIds: dataValues?.sellerIds?.map((seller) => seller.id).join(','),
				licensePlate: dataValues?.licensePlate,
				status: dataValues?.status,
				userSearched: dataValues?.user_searched,
				onlyInvoiced,
			});
			FileDownload(exportReports, 'Histórico de pesquisas.xlsx');
		} catch(error){
			addToast(`Ocorreu um erro ao tentar exportar o relatório.`, {
				appearance: 'error',
				autoDismiss: true,
			});
		}finally{
			setReportsLoading(false)
		}
	}

	async function exportFinancialReports() {
		setFinancialReportsLoading(true)
		try{
			const exportFinancialReports = await reportsApi.getExportedFinancialReport({
				startDate: dataValues?.startDate,
				endDate: utcToBrazillianEndDate(new Date(dataValues?.endDate)).toString(),
				sellerIds: dataValues?.sellerIds?.map((seller) => seller.id).join(','),
			});
			FileDownload(exportFinancialReports, 'Relatório Financeiro.xlsx');
		} catch(error){
			addToast(`Ocorreu um erro ao tentar exportar o relatório.`, {
				appearance: 'error',
				autoDismiss: true,
			});
		}finally{
			setFinancialReportsLoading(false)
		}
	}

	const getUsersBySeller = async () => {
		if (!isBackoffice) {
			setUsersLoading(true);
			try {
				const users = await usersApi.getUsersBySeller();
				setUsers(users);
			} catch (error) {
				addToast(`Ocorreu um erro ao tentar buscar os usuários do cartório.`, {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setUsersLoading(false);
			}
		}
	};

	const handleFormSubmit = async () => {
		const startDate = period[0].startDate.toString();
		const endDate = period[0].endDate.toString();
		const currentPage = 1;
		const dataValues = { startDate, endDate, sellerIds, transactionNumber, limit, currentPage, licensePlate, status, user_searched: userSearched };
		setDataValues(dataValues);
		setCurrentPage(1);
		fetchData(dataValues);
	};

	useEffect(() => {
		if (isBackoffice) {
			fetchSellersData();
		}
		fetchData(dataValues);
		getUsersBySeller()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Flex flexDirection='column' w='100%' px={4} pt='20px' minH='88%' overflowX='auto'>
			<Flex flexDirection='column' w='100%'>
				<Formik enableReinitialize initialValues={initialValues} onSubmit={handleFormSubmit} validationSchema={schema}>
					{({ handleSubmit, setFieldValue }) => {
					return (
						<form onSubmit={handleSubmit}>
							<Stack
								direction={['column', 'column', 'row']}
								alignItems={['center', 'center', 'flex-start']}
								spacing='20px'
								mb='20px'
								mt='40px'
								w='100%'
								justify='flex-start'
								wrap='wrap'
							>
								<Flex>
									<FormControl>
										<RangeDatePicker
											id='input__date-picker'
											name='period'
											size='md'
											p='24px'
											period={period}
											setPeriod={setPeriod}
											handleSubmit={() => {}}
										/>
									</FormControl>
								</Flex>

								{isBackoffice && (
									<Flex w='200px'>
										<FormControl>
											<Select
												id='input__cns-name'
												name='cns'
												placeholder='Buscar'
												isMulti
												styles={customStyle}
												options={sellersData}
												onChange={(e) => setSellerIds(e as SellersOptions[])}
											/>
										</FormControl>
									</Flex>
								)} 

								<Flex w='200px'>
									<FormControl>
										<Input
											border='2px solid black'
											borderRadius='15px'
											size='md'
											placeholder='ID'
											name='transactionNumber'
											id='input__transaction-number'
											onChange={(e) => setTransactionNumber(e.target.value)}
										/>
									</FormControl>
								</Flex>

								<Flex w='200px'>
									<FormControl>
										<Input
											border='2px solid black'
											borderRadius='15px'
											size='md'
											placeholder='Placa'
											name='licensePlate'
											id='input__license-plate'
											maxLength={7}
											value={licensePlate}
											onChange={(e) => {
												const upperCaseValue = e.target.value.toUpperCase();
												setFieldValue('licensePlate', upperCaseValue);
												setLicensePlate(upperCaseValue);
											}}
										/>
										<Flex w='100%' justifyContent='center'>
											<Flex justifyContent='flex-start'>
												<FormErrorMessage name='licensePlate' />
											</Flex>
										</Flex>
									</FormControl>
								</Flex>
								<Stack spacing={4} w='200px'>
									<InputGroup size='md' border='2px solid black' borderRadius='15px'>
										<InputLeftAddon bgColor='lightgray' border='none' borderRadius='15px 0px 0px 15px'>
											Status
										</InputLeftAddon>
										<ChakraSelect
											name='success'
											id='input__success'
											borderRadius='0px 15px 15px 0px'
											onChange={(e) => {
												setStatus(e.target.value);
												if (e.target.value === ReportStatusEnum.Failed) {
													setOnlyInvoiced(false)
												}
											}}
											border='none'
											value={status}
										>
											<option value=''>Todos</option>
											<option value={ReportStatusEnum.Success}>Sucesso</option>
											<option value={ReportStatusEnum.Failed}>Falha</option>
										</ChakraSelect>
									</InputGroup>
								</Stack>
								{!isBackoffice && (
										<Stack spacing={4} w='200px'>
											<InputGroup size='md' border='2px solid black' borderRadius='15px'>
												<InputLeftAddon bgColor='lightgray' border='none' borderRadius='15px 0px 0px 15px'>
													Usuários
												</InputLeftAddon>
												<ChakraSelect
													isDisabled={usersLoading}
													name='user_searched'
													id='input__user-searched'
													borderRadius='0px 15px 15px 0px'
													onChange={(e) => {
														setFieldValue('user_searched', e.target.value);
														setUserSearched(e.target.value);
													}}
													border='none'
													value={userSearched}
												>
													<option value=''>Todos</option>
													{users.map((user) => (
														<option value={user.name}>{user.name}</option>
													))}
												</ChakraSelect>
											</InputGroup>
										</Stack>
								)}
								<Flex w='200px'>
									<FormControl p='2'>
										<Checkbox
											id='input__only-invoiced'
											name='onlyInvoiced'
											color='#757575'
											size='lg'
											borderColor='black'
											isChecked={onlyInvoiced}
											onChange={(e) => {
												setOnlyInvoiced(e.target.checked);
												if (e.target.checked) {
													setStatus(ReportStatusEnum.Success);
												}
											}}
										>
											Apenas faturadas
										</Checkbox>
									</FormControl>
								</Flex>
								<Flex maxW='200px' flex='0 1 auto'>
									<FormControl>
										<Button 
										    id='button__search' 
											type='submit'
											size='md'
											isLoading={loading}
											border={`2px solid ${defaultColors.secondaryColor}`}
											bgColor={defaultColors.primaryColor}
											borderRadius='10px'
											w='100%'
										>
											Consultar
										</Button>
									</FormControl>
								</Flex>
							</Stack>
						</form>
					)}}
				</Formik>
				<Stack mb={4} alignItems='center'>
					{loading ? (
						<Spinner size='lg' mt='40px' />
					) : (
						<SimpleTable
							limit={limit}
							currentPage={currentPage}
							fetchData={fetchData}
							setCurrentPage={setCurrentPage}
							setLimit={setLimit}
							setDataValues={setDataValues}
							dataValues={dataValues}
							reports={reportsData}
							body={reportsData?.results}
						/>
					)}
				</Stack>
			</Flex>
			{!reportsData?.results === undefined ||
				(reportsData?.results.length !== 0 && (
					<Stack
						justifyContent={['center', 'center','flex-end']}
						alignContent={['center','center', 'flex-end']}
						direction={['column', 'row']}
						spacing='20px'
						mb='20px'
						wrap='wrap'
						ml={['0', '0', 'auto']}
						mt={['0','0', 'auto']}
						
					>
						{!loading && (
							<>
								<Button
									isLoading={reportsLoading}
									id='button__export'
									h='40px'
									border={`2px solid ${defaultColors.secondaryColor}`}
									bgColor={defaultColors.primaryColor}
									borderRadius='10px'
									onClick={() => exportReports()}
								>
									Exportar Relatório
								</Button>
								<Button
									isLoading={financialReportsLoading}
									id='button__export-financial'
									h='40px'
									border={`2px solid ${defaultColors.secondaryColor}`}
									bgColor={defaultColors.primaryColor}
									borderRadius='10px'
									onClick={() => exportFinancialReports()}
								>
									Exportar Relatório Financeiro
								</Button>
							</>
						)}
					</Stack>
				))}
		</Flex>
	);
};

export default Reports;
