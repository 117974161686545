export enum RealTimeChannel {
    PAYMENT = 'payment',
    ABOUTCAR = 'aboutcar',
    MAINTENANCE_MODE = 'maintenance_mode',
    DEBTS = 'debts',
    ATPVE = 'atpve',
    SEARCH_BATCH = 'search_batch'
};

export enum RealTimeEvent {
    PAYMENT_UPDATE = 'payment_update',
    SEARCH_DEBTS = 'search_debts',
    MAINTENANCE_STATE = 'maintenance_state',
    INQUIRIES = 'inquiries',
    ATPVE_DOCUMENT = 'atpve_document',
    SEARCH_BATCH = 'search_batch'
}