import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay } from '@chakra-ui/react';
import React from 'react';

import Button from '../../components/Button';
import Text from '../../components/Text';
import Title from '../../components/Title';

const Welcome: React.FC = () => {
	const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(true);
	return (
		<Flex padding='50px' flexDirection='column'>
			<Title fontWeight='350' textAlign='center' fontSize='5xl'>
				Bem-vindo a CNTV MG
			</Title>
			<Text textIndent='50px' textAlign={['justify', 'start']} marginTop='30px'>
				Caro notário(a),
			</Text>
			<Text textIndent='50px' textAlign={['justify', 'start']} marginTop='5px'>
				Bem-vindo a CNTV MG (Central Notarial de Transferência Veicular de Minas Gerais),
				<br />
			</Text>

			<Text textIndent='50px' textAlign='justify' marginTop='30px'>
				A CNTV.MG foi criada em atendimento ao art. 18-A no seu § 3º da Lei nº 15.424, de 2004, e entrou oficialmente em funcionamento no
				dia 11 de março de 2024 e a nova versão estará dispinível no dia 01 Agosto de 2024. A partir desta data, a consulta individual e
				obrigatória a CNTV.MG, terá um custo de R$ 4,80 (quatro reais e oitenta centavos). “Os Notários deverão consultar central eletrônica
				própria previamente ao ato de reconhecimento de firma em autorizações para transferência de veículos automotores, aplicando-se nesse
				caso o disposto no art. 17 desta lei.”.
			</Text>

			<Modal
				size='2xl'
				isOpen={modalIsOpen}
				onClose={() => {
					setModalIsOpen(false);
				}}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<Text textIndent='50px' textAlign='center' fontWeight='bold' marginTop='30px'>
							AVISO - CNTV-CNB/MG
						</Text>
						<Text textIndent='50px' textAlign='justify' marginTop='30px'>
							A partir do dia 15/08, uma vez realizada a consulta na CNTV de um determinado veículo, por ocasião do segundo reconhecimento
							de firma, caso realizado no mesmo dia e na mesma serventia, não será necessária nova consulta. O CNTV irá alertar caso a placa
							já tenha sido consultada no mesmo dia.
						</Text>
					</ModalBody>
					<ModalFooter>
						<Button onClick={() => setModalIsOpen(false)}>OK</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Flex>
	);
};

export default Welcome;
