/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetSummaryByDateResponseDto,
  GetSummaryResponseDto,
} from '../models/index';
import {
    GetSummaryByDateResponseDtoFromJSON,
    GetSummaryByDateResponseDtoToJSON,
    GetSummaryResponseDtoFromJSON,
    GetSummaryResponseDtoToJSON,
} from '../models/index';

export interface GetSummaryByDateRequest {
    startDate: string;
    endDate: string;
}

/**
 * 
 */
export class SummariesApi extends runtime.BaseAPI {

    /**
     * Buscar informações do resumo
     */
    async getSummaryRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSummaryResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/summaries/summary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSummaryResponseDtoFromJSON(jsonValue));
    }

    /**
     * Buscar informações do resumo
     */
    async getSummary(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSummaryResponseDto> {
        const response = await this.getSummaryRaw(initOverrides);
        return await response.value();
    }

    /**
     * Buscar informações do resumo por data
     */
    async getSummaryByDateRaw(requestParameters: GetSummaryByDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSummaryByDateResponseDto>> {
        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getSummaryByDate.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getSummaryByDate.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/summaries/summary-by-date`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSummaryByDateResponseDtoFromJSON(jsonValue));
    }

    /**
     * Buscar informações do resumo por data
     */
    async getSummaryByDate(requestParameters: GetSummaryByDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSummaryByDateResponseDto> {
        const response = await this.getSummaryByDateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
