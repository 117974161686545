/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchBatch
 */
export interface SearchBatch {
    /**
     * 
     * @type {string}
     * @memberof SearchBatch
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SearchBatch
     */
    seller_id: string;
    /**
     * 
     * @type {string}
     * @memberof SearchBatch
     */
    seller_name: string;
    /**
     * 
     * @type {string}
     * @memberof SearchBatch
     */
    seller_cns: string;
    /**
     * 
     * @type {string}
     * @memberof SearchBatch
     */
    user_name: string;
    /**
     * 
     * @type {string}
     * @memberof SearchBatch
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchBatch
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SearchBatch
     */
    status: SearchBatchStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof SearchBatch
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof SearchBatch
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof SearchBatch
     */
    updated_at?: string;
}


/**
 * @export
 */
export const SearchBatchStatusEnum = {
    Pending: 'pending',
    Success: 'success',
    Failed: 'failed'
} as const;
export type SearchBatchStatusEnum = typeof SearchBatchStatusEnum[keyof typeof SearchBatchStatusEnum];


/**
 * Check if a given object implements the SearchBatch interface.
 */
export function instanceOfSearchBatch(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "seller_id" in value;
    isInstance = isInstance && "seller_name" in value;
    isInstance = isInstance && "seller_cns" in value;
    isInstance = isInstance && "user_name" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "created_at" in value;

    return isInstance;
}

export function SearchBatchFromJSON(json: any): SearchBatch {
    return SearchBatchFromJSONTyped(json, false);
}

export function SearchBatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchBatch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'seller_id': json['seller_id'],
        'seller_name': json['seller_name'],
        'seller_cns': json['seller_cns'],
        'user_name': json['user_name'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'email': json['email'],
        'status': json['status'],
        'quantity': json['quantity'],
        'created_at': json['created_at'],
        'updated_at': !exists(json, 'updated_at') ? undefined : json['updated_at'],
    };
}

export function SearchBatchToJSON(value?: SearchBatch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'seller_id': value.seller_id,
        'seller_name': value.seller_name,
        'seller_cns': value.seller_cns,
        'user_name': value.user_name,
        'url': value.url,
        'email': value.email,
        'status': value.status,
        'quantity': value.quantity,
        'created_at': value.created_at,
        'updated_at': value.updated_at,
    };
}

