/* tslint:disable */
/* eslint-disable */
/**
 * Parcela CNTV API
 * Parcela CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddPayerDto,
  GetReportsDto,
} from '../models/index';
import {
    AddPayerDtoFromJSON,
    AddPayerDtoToJSON,
    GetReportsDtoFromJSON,
    GetReportsDtoToJSON,
} from '../models/index';

export interface AddPayerToReportRequest {
    reportId: string;
    addPayerDto: AddPayerDto;
}

export interface GetExportedFinancialReportRequest {
    startDate: string;
    endDate: string;
    sellerIds?: string;
}

export interface GetExportedReportRequest {
    startDate: string;
    endDate: string;
    limit: number;
    currentPage: number;
    transactionNumber?: string;
    sellerIds?: string;
    licensePlate?: string;
    status?: string;
    userSearched?: string;
    onlyInvoiced?: boolean;
}

export interface GetReportsRequest {
    startDate: string;
    endDate: string;
    limit: number;
    currentPage: number;
    transactionNumber?: string;
    sellerIds?: string;
    licensePlate?: string;
    status?: string;
    userSearched?: string;
    onlyInvoiced?: boolean;
}

/**
 * 
 */
export class ReportsApi extends runtime.BaseAPI {

    /**
     * Adicionar Payer a um Report
     */
    async addPayerToReportRaw(requestParameters: AddPayerToReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling addPayerToReport.');
        }

        if (requestParameters.addPayerDto === null || requestParameters.addPayerDto === undefined) {
            throw new runtime.RequiredError('addPayerDto','Required parameter requestParameters.addPayerDto was null or undefined when calling addPayerToReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/reports/{report_id}/add-payer`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AddPayerDtoToJSON(requestParameters.addPayerDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Adicionar Payer a um Report
     */
    async addPayerToReport(requestParameters: AddPayerToReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addPayerToReportRaw(requestParameters, initOverrides);
    }

    /**
     * Exportar relatório financeiro
     */
    async getExportedFinancialReportRaw(requestParameters: GetExportedFinancialReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getExportedFinancialReport.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getExportedFinancialReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        if (requestParameters.sellerIds !== undefined) {
            queryParameters['seller_ids'] = requestParameters.sellerIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/reports/export-financial`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Exportar relatório financeiro
     */
    async getExportedFinancialReport(requestParameters: GetExportedFinancialReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getExportedFinancialReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Exportar relatório
     */
    async getExportedReportRaw(requestParameters: GetExportedReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getExportedReport.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getExportedReport.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getExportedReport.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling getExportedReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        if (requestParameters.transactionNumber !== undefined) {
            queryParameters['transaction_number'] = requestParameters.transactionNumber;
        }

        if (requestParameters.sellerIds !== undefined) {
            queryParameters['seller_ids'] = requestParameters.sellerIds;
        }

        if (requestParameters.licensePlate !== undefined) {
            queryParameters['license_plate'] = requestParameters.licensePlate;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.userSearched !== undefined) {
            queryParameters['user_searched'] = requestParameters.userSearched;
        }

        if (requestParameters.onlyInvoiced !== undefined) {
            queryParameters['only_invoiced'] = requestParameters.onlyInvoiced;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/reports/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Exportar relatório
     */
    async getExportedReport(requestParameters: GetExportedReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getExportedReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Buscar relatórios realizados
     */
    async getReportsRaw(requestParameters: GetReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetReportsDto>> {
        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getReports.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getReports.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getReports.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling getReports.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        if (requestParameters.transactionNumber !== undefined) {
            queryParameters['transaction_number'] = requestParameters.transactionNumber;
        }

        if (requestParameters.sellerIds !== undefined) {
            queryParameters['seller_ids'] = requestParameters.sellerIds;
        }

        if (requestParameters.licensePlate !== undefined) {
            queryParameters['license_plate'] = requestParameters.licensePlate;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.userSearched !== undefined) {
            queryParameters['user_searched'] = requestParameters.userSearched;
        }

        if (requestParameters.onlyInvoiced !== undefined) {
            queryParameters['only_invoiced'] = requestParameters.onlyInvoiced;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/reports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetReportsDtoFromJSON(jsonValue));
    }

    /**
     * Buscar relatórios realizados
     */
    async getReports(requestParameters: GetReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetReportsDto> {
        const response = await this.getReportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
