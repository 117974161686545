import React from 'react';

import { Flex, Stack } from '@chakra-ui/react';
import { FcDocument, FcMoneyTransfer, FcRules, FcViewDetails } from 'react-icons/fc';

import { GetConsultDebtLinkDto, GetVehicleHistoryPDFV2TypeEnum, VehiclesApi } from '../../../clients';

import { PagesEnum } from '../enums/pages.enum';

import Text from '../../../components/Text';
import Title from '../../../components/Title';
import ConsultButton from '../components/ConsultButton';
import { useToasts } from 'react-toast-notifications';
import { getApiDefaultConfig } from '../../../services/api.service';
import fileDownload from 'js-file-download';

type ConsultSelectProps = {
	consultDebtsData: GetConsultDebtLinkDto | undefined;
	setStage: React.Dispatch<React.SetStateAction<number>>;
};

export const ConsultSelect: React.FC<ConsultSelectProps> = (props: ConsultSelectProps) => {
	const apiConfig = getApiDefaultConfig();
	const vehiclesApi = new VehiclesApi(apiConfig);
	const { addToast } = useToasts();
	
	const [freeConsultLoading, setFreeConsultLoading] = React.useState<boolean>(false);
	const [receiptLoading, setReceiptLoading] = React.useState<boolean>(false);


	const handleDownloadReceipt = async () => {
		setReceiptLoading(true);
		const reportId = props?.consultDebtsData?.report_id;
		try {
			if (reportId) {
				const response = await vehiclesApi.getVehicleReceipt({ reportId });
				fileDownload(response, `Dados Complementares ${props?.consultDebtsData?.license_plate}.pdf`);
			}
		} catch (error) {
			addToast('Erro ao realizar a consulta do histórico veicular.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setReceiptLoading(false);
		}
	};

	const handleDownloadHistory = () => {
		if (props?.consultDebtsData?.history_url) {
			const link = document.createElement('a');
			link.href = props.consultDebtsData.history_url;
			link.download = 'Consulta Completa.pdf';
			link.click();
		}
	};

	const handleDownloadFreeConsultVehiclePDF = async () => {
		setFreeConsultLoading(true);
		const reportId = props.consultDebtsData?.report_id
		try {
			if (reportId) {
				const type = GetVehicleHistoryPDFV2TypeEnum.Pexbasica;
				const response: any = await vehiclesApi.getVehicleHistoryPDFV2({ reportId , type });
				fileDownload(response, `Consulta Veicular ${props.consultDebtsData?.license_plate}.pdf`);
			}
		} catch (error) {
			addToast('Erro ao realizar a consulta do histórico veicular.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setFreeConsultLoading(false);
		}
	};
	return (
		<>
			<Stack minH='76vh' justifyContent='space-evenly' p={5}>
				<Stack alignItems='center'>
					<Title textAlign={['center', 'start']}>Bem Vindo à Consulta Veicular</Title>
					<Text textAlign={['center', 'start']}>Placa do Veículo: {props.consultDebtsData?.license_plate.toUpperCase()}</Text>
					<Text textAlign={['center', 'start']}>Nome do Proprietário: {props.consultDebtsData?.owner_name}</Text>
				</Stack>
				<Stack w='100%' flexDirection={['column', 'row']} justifyContent='center' gap='50px'>
					<ConsultButton
						label='Resultado e Recibo da Consulta'
						description='Faça o download do resultado e recibo de consulta.'
						iconSrc={<FcDocument size={48} />}
						isDisabled={receiptLoading}
						className='button__download-receipt'
						onClick={handleDownloadReceipt}
					/>

					<ConsultButton
						label='Dados Complementares do Veículo'
						description='Realize uma consulta dos dados complementares do veículo.'
						iconSrc={<FcRules size={48} />}
						className='button__consult-free-vehicle-data'
						isDisabled={freeConsultLoading}
						onClick={handleDownloadFreeConsultVehiclePDF}
					/>

					<Flex flexDirection='column' justifyContent={'center'}>
						<ConsultButton
							label='Consulta Veicular Completa'
							description='Realize uma consulta completa dos dados do veículo.'
							iconSrc={<FcViewDetails size={48} />}
							className='button__consult-vehicle-data'
							isDisabled={!props.consultDebtsData?.history_url}
							onClick={() => handleDownloadHistory()}
						/>
						{
							!props.consultDebtsData?.history_url && (
								<Text color={'red'} textAlign={'center'} fontSize={'smaller'}>
									Essa consulta completa está sendo <br/>atualizada, em breve estará disponível
								</Text>
							)
						}
					</Flex>

					{props.consultDebtsData?.vehicle_state === 'MG' && (
						<ConsultButton
							label='Consulta de Débitos Veiculares'
							description='Busque por IPVA, Licenciamento e Multas em aberto.'
							iconSrc={<FcMoneyTransfer size={48} />}
							className='button__consult-debts'
							onClick={() => props.setStage(PagesEnum.ConsultDebts)}
						/>
					)}
				</Stack>
			</Stack>
		</>
	);
};
