import { Flex, InputGroup, InputRightElement, Radio, RadioGroup, Spinner, Stack, useDisclosure } from '@chakra-ui/react';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { GetVehicleByLicensePlateDocumentTypeEnum, VehiclesApi, GetSameDayReportDto } from '../../clients';

import { phoneMask } from '../../services/mask.service';

import { format } from 'date-fns';
import Button from '../../components/Button';
import FormControl from '../../components/Form/FormControl';
import FormErrorMessage from '../../components/Form/FormErrorMessage';
import Input from '../../components/Input';
import Text from '../../components/Text';
import Title from '../../components/Title';
import { defaultColors } from '../../config/variables';
import { getApiAuthConfig } from '../../services/api.service';
import ConfirmModal from './components/ConfirmModal';
import { generateUuid } from '../../services/uuid.service';

type formValues = {
	crvAtpve: GetVehicleByLicensePlateDocumentTypeEnum;
	licensePlate: string;
	whatsapp: string;
	email?: string;
};

const Search: React.FC = () => {
	const apiConfig = getApiAuthConfig();
	const vehiclesApi = new VehiclesApi(apiConfig);
	const { isOpen, onOpen, onClose } = useDisclosure()
	const navigate = useNavigate();

	const [crvAtpve, setCrvAtpve] = useState<GetVehicleByLicensePlateDocumentTypeEnum>(GetVehicleByLicensePlateDocumentTypeEnum.Crv);
	const [licensePlate, setLicensePlate] = useState<string>('');
	const [isSameDayReport, setIsSameDayReport] = useState<boolean>(false);
	const [reportFound, setReportFound] = useState<GetSameDayReportDto | null>(null);
	const [isSameDayLoading, setIsSameDayLoading] = useState<boolean>(false);
	const [acceptNewConsult, setAcceptNewConsult] = useState<boolean>(false);
	const [isLoading, setIsloading] = useState<boolean>(false);


	const initialValues = {
		licensePlate: '',
		crvAtpve: GetVehicleByLicensePlateDocumentTypeEnum.Crv,
		whatsapp: '',
		email: '',
	};

	const getSameDayReport = async () => {
		setIsSameDayLoading(true);
		try {
			const result: GetSameDayReportDto = await vehiclesApi.getSameDayReport({ documentType: crvAtpve, licensePlate });
			setIsSameDayReport(result.isSameDay);
			setReportFound(result);
		} catch (error) {
		} finally {
			setIsSameDayLoading(false);
		}
	};

	const schema = yup.object().shape({
		licensePlate: yup
			.string()
			.matches(/^[a-zA-Z]{3}\d[a-zA-Z\d]\d{2}$/, { message: 'Formato de placa inválido', excludeEmptyString: true })
			.required('Campo obrigatório'),
		whatsapp: yup.string().required('Campo obrigatório').length(15, 'O telefone deve conter 11 dígitos'),
		email: yup.string().email('Digite um email válido ou deixe em branco'),
	});

	const handleFormSubmit = async () => {
		onOpen();
	};

	const handleModalSubmit = async (values: formValues) => {
		setIsloading(true);
		const whatsapp = values.whatsapp.replace(/\D/g, '');
		const email = values.email !== '' ? values.email : undefined;

		const uuid = generateUuid();
		const chunks = uuid.split('-');

    const transactionNumber = `${chunks[0]}${chunks[1]}${chunks[2]}`;

		navigate(
			`/admin/resultado/${transactionNumber}`,
			{ state: { licensePlate, crvAtpve, whatsapp, email }, replace: true }
		);
	};

	useEffect(() => {
		if (licensePlate.length === 7) {
			getSameDayReport();
		} else {
			setIsSameDayReport(false);
			setAcceptNewConsult(false);
		}
		// eslint-disable-next-line
	}, [licensePlate]);

	useEffect(() => {
		setAcceptNewConsult(false);
		setIsSameDayReport(false);

		if (licensePlate.length === 7) {
			getSameDayReport();
		}
		// eslint-disable-next-line
	}, [crvAtpve]);

	const handleAcceptClick = () => {
		setAcceptNewConsult(true);
	};

	const handleDeclineClick = (resetForm: () => void) => {
		resetForm();
		setAcceptNewConsult(false);
		setIsSameDayReport(false);
		setLicensePlate('');
	};

	return (
		<Flex flexDirection='column' py='20px' paddingRight='50px' w='100%' minH='85%' pl={[5, 5, 0]}>
			<Flex flexDirection='column' h='100%'>
				<Flex mb='40px' mt='40px'>
					<Flex direction={['column']}>
						<Flex marginBottom='40px' borderBottom='2px solid black' maxW={['100%', '100%', '70%']}>
							<Title fontSize={['lg', 'lg', '3xl']} fontWeight={350}>
								Central Notarial de Transferência Veicular
							</Title>
						</Flex>
						<Title textAlign='justify' fontSize={['sm', 'md', 'lg']} fontWeight={350}>
							Sistema do Colégio Notarial de Minas Gerais para consultas seguras aos dados originários do certificado de registro do veículo
							(CRV) e da autorização para Transferência de Propriedade do Veículo (ATPVe).
						</Title>
					</Flex>
				</Flex>
				<Flex mb='40px'>
					<Stack w={['100%', '100%', '60%']}>
						<Title fontSize={['lg', 'lg', '3xl']} textAlign={['center', 'center', 'start']} fontWeight={350}>
							Consulta CRV / ATPVe
						</Title>
						<Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleFormSubmit}>
							{({ handleSubmit, setFieldValue, values, resetForm }) => {
								return (
									<form onSubmit={handleSubmit}>
										<Stack spacing='20px'>
											<ConfirmModal
												isOpen={isOpen}
												onClose={onClose}
												licensePlate={licensePlate}
												type={crvAtpve}
												handleModalSubmit={() => handleModalSubmit(values)}
												resetForm={() => resetForm()}
												crvAtpve={crvAtpve}
												setAcceptNewConsult={setAcceptNewConsult}
												setIsSameDayReport={setIsSameDayReport}
												setLicensePlate={setLicensePlate}
											/>
											<Stack spacing='20px'>
												<FormControl display='flex' flexDirection='row' mb='20px'>
													<RadioGroup
														name='crvAtpve'
														fontSize='xxx-large'
														color='black'
														w={['100%', '100%', '67%']}
														defaultValue={GetVehicleByLicensePlateDocumentTypeEnum.Crv}
														onChange={(e: GetVehicleByLicensePlateDocumentTypeEnum) => setCrvAtpve(e)}
														isDisabled={isSameDayLoading}
													>
														<Stack justifyContent={['center', 'center', 'start']} spacing='80px' direction='row'>
															<Radio border='2px solid grey' id='input__radio-crv' size='lg' value={GetVehicleByLicensePlateDocumentTypeEnum.Crv}>
																CRV
															</Radio>
															<Radio border='2px solid grey' id='input__radio-atpve' size='lg' value={GetVehicleByLicensePlateDocumentTypeEnum.Atpve}>
																ATPVe
															</Radio>
														</Stack>
													</RadioGroup>
												</FormControl>

												<FormControl display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' mb='20px'>
													<Flex display='flex' flexDirection={['column', 'column', 'row']} w='100%'>
														<InputGroup>
															<Input
																name='licensePlate'
																id='input__license-plate'
																placeholder='Digite a Placa do Veículo'
																textAlign={['center', 'center', 'start']}
																isReadOnly={false}
																w='100%'
																maxLength={7}
																isDisabled={isSameDayLoading}
																border={`1px solid ${defaultColors.lightGreen}`}
																onChange={(e) => {
																	const value = e.target.value.toUpperCase();
																	setFieldValue('licensePlate', value);
																	setLicensePlate(value);
																}}
															/>
															{isSameDayLoading && (
																<InputRightElement>
																	<Spinner size='sm' />
																</InputRightElement>
															)}
														</InputGroup>
													</Flex>
													<Flex w='100%' justifyContent='flex-end'>
														<Flex w='67%' justifyContent='flex-start'>
															<FormErrorMessage name='licensePlate' />
														</Flex>
													</Flex>
												</FormControl>

												<FormControl display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' mb='20px'>
													<Flex display='flex' flexDirection={['column', 'column', 'row']} w='100%'>
														<Input
															name='whatsapp'
															id='input__whatsApp'
															placeholder='Digite o Telefone do Cliente'
															textAlign={['center', 'center', 'start']}
															isReadOnly={false}
															w='100%'
															maxLength={15}
															onChange={(e) => setFieldValue('whatsapp', phoneMask(e.target.value))}
															border={`1px solid ${defaultColors.lightGreen}`}
														/>
													</Flex>
													<Flex w='100%' justifyContent='flex-end'>
														<Flex w='67%' justifyContent='flex-start'>
															<FormErrorMessage name='whatsapp' />
														</Flex>
													</Flex>
												</FormControl>

												<FormControl display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' mb='20px'>
													<Flex display='flex' flexDirection={['column', 'column', 'row']} w='100%'>
														<Input
															name='email'
															id='input__email'
															placeholder='Digite o Email do Cliente (Opcional)'
															textAlign={['center', 'center', 'start']}
															isReadOnly={false}
															w='100%'
															onChange={(e) => setFieldValue('email', e.target.value?.trim())}
															border={`1px solid ${defaultColors.lightGreen}`}
														/>
													</Flex>
													<Flex w='100%' justifyContent='flex-end'>
														<Flex w='67%' justifyContent='flex-start'>
															<FormErrorMessage name='email' />
														</Flex>
													</Flex>
												</FormControl>
												
												{isSameDayReport && !acceptNewConsult ? (
													<Flex flexDirection='column' p='20px' border='1px solid' borderColor='gray.200' borderRadius='md' bg='gray.50'>
														<Flex flexDirection='column' mb='20px'>
															<Text fontSize='lg' fontWeight='medium' mb='10px' textAlign='justify'>
																<strong>Atenção:</strong>
															</Text>
															<Text fontSize='md' mb='10px' textAlign='justify'>
																Esta placa já foi consultada nesta serventia, na data de hoje:{' '}
																<strong>
																	{reportFound?.created_at ? format(new Date(reportFound.created_at), "dd/MM/yyyy 'às' HH:mm:ss") : ''}
																</strong>
																.
															</Text>
															<Text fontSize='md' mb='10px' textAlign='justify'>
																Neste caso, não é necessário realizar uma nova consulta. Prossiga com o segundo reconhecimento de firma!
															</Text>
															<Text fontSize='md' color='red.500' textAlign='justify'>
																Caso realize uma nova consulta, será realizada nova cobrança.
															</Text>
														</Flex>
														<Flex flexDirection='column'>
															<Text fontSize='lg' fontWeight='medium' mb='10px' textAlign='justify'>
																Deseja realizar uma nova consulta?
															</Text>
															<Flex gap='20px' mt='10px'>
																<Button bgColor='red.500' color='white' onClick={() => handleDeclineClick(resetForm)}>
																	Não
																</Button>
																<Button colorScheme='teal' onClick={handleAcceptClick}>
																	Sim
																</Button>
															</Flex>
														</Flex>
													</Flex>
												) : (
													<Flex justifyContent={['center', 'center', 'start']}>
														<Button
															id='button__search'
															type='submit'
															isDisabled={isSameDayLoading}
															isLoading={isLoading}
															bgColor={defaultColors.primaryColor}
															border={`2px solid ${defaultColors.secondaryColor}`}
														>
															Consultar
														</Button>
													</Flex>
												)}
											</Stack>
										</Stack>
									</form>
								);
							}}
						</Formik>
					</Stack>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default Search;
