import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { FaWhatsapp } from 'react-icons/fa';

import { defaultColors } from '../config/variables';
import { hiddenFooterUrls } from '../config/hiddenFooterUrls';

import Text from '../components/Text';

const Footer: React.FC = () => {
	const url = window.location.pathname.split('/');
	const displayStyle = hiddenFooterUrls.includes(url[1]) ? 'none' : 'flex';
	
	return (
		<Flex flexDirection='column' h={['25%', '15%', '12%']} display={displayStyle}>
			<Flex
				alignItems='center'
				justifyContent='space-around'
				backgroundColor={defaultColors.primaryColor}
				paddingX='30px'
				width='100%'
				h='100%'
				flexDirection={['column', 'row']}
			>
				<Box textAlign='center' color={defaultColors.white} fontSize='sm' flexDirection='column'>
					<Text fontWeight='bold'>ENDEREÇO</Text>
					<Text>Av. Brasil, N1479, Bairro Funcionários</Text>
					<Text>Belo Horizonte/MG | CEP 30140-005</Text>
				</Box>
				<Box textAlign='center' color={defaultColors.white} fontSize='sm' flexDirection='column'>
					<Text fontWeight='bold'>CENTRAL DE SUPORTE</Text>
					<Flex>
						<Text mr='10px'>Telefone/WhatsApp:</Text>
						<Flex alignItems='center'>
							<FaWhatsapp />
						</Flex>
						<Text>(31) 98259-8606</Text>
					</Flex>
					<Text>E-mail: suportecntv@cnbmg.org.br</Text>
				</Box>
			</Flex>
		</Flex>
	);
};

export default Footer;
